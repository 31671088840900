'use client';

import Image from 'next/image';
import CurrencyFormattedNumber from '../CurrencyNumber';
import Drawer from '../Drawer';
import { ShareLink } from '../ShareLink';
import { SocialButtons } from '../SocialButtons';
import { useEffect, useState } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';
import InstagramShare from './components/InstagramShare';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { mergeClasses } from '@/src/lib';

export interface ShareDrawerProps {
  url?: string;
  isOpen: boolean;
  onClose: () => void;
  heading?: string;
  subHeading?: string;
  contentClassName?: string;
  imgProps?: {
    src: string;
    className: string;
    width: number;
    height: number;
    quality: number;
    alt: string;
  };
  useUserIdAsSrc?: boolean;
  src?: string;
  shouldShowStat?: boolean;
  enableInstagramShare?: boolean;
  slug?: string;
}

export const ShareDrawer = ({
  slug,
  url,
  isOpen,
  onClose,
  heading = 'Invite to good',
  subHeading,
  contentClassName = '',
  imgProps = {
    src: 'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2F2a085ff736ad45f9a5ae4f31ca9e4cd7',
    width: 367,
    height: 177,
    quality: 100,
    className: 'w-[360px] h-[114px]',
    alt: 'Sharing image',
  },
  useUserIdAsSrc = false,
  src,
  shouldShowStat = true,
  enableInstagramShare = false,
}: ShareDrawerProps) => {
  const { user } = useAuthHook();

  const [shareUrl, setShareUrl] = useState<string>(url || '');

  const [showInstagramShareContent, setShowInstagramShareContent] =
    useState(false);

  useEffect(() => {
    if (url) {
      setShareUrl(url);
    }
    if (!src && !useUserIdAsSrc) return;

    if (src && useUserIdAsSrc && user) {
      src = `${src}&userid=${user['user-id'].toString()}`;
    } else if (useUserIdAsSrc && user) {
      src = user['user-id'].toString();
    }
    setShareUrl(`${url}${src ? '?src=' + src : ''}`);
  }, [user, url]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      setShowInstagramShareContent(false);
    }
  }, [isOpen]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      {!showInstagramShareContent && (
        <div className={mergeClasses(['p-8', contentClassName])}>
          <h2 className="font-bold text-xl mb-3">{heading}</h2>
          {subHeading && (
            <h3 className="text-base text-rebuild-gray-500 mb-4">
              {subHeading}
            </h3>
          )}
          {shouldShowStat && (
            <p className="mb-8 text-rebuild-gray-500">
              Giving is better together! Your share could raise over{' '}
              <CurrencyFormattedNumber
                element="span"
                amount={77}
                currencyCode="USD"
              />
              .
            </p>
          )}
          <div className="mb-6">
            <SocialButtons internalShareUrl={shareUrl}>
              {enableInstagramShare && (
                <Button
                  className={`${buttonVariants({
                    variant: 'social',
                  })} aspect-square h-full w-full sm:hidden`}
                  size="lg"
                  onClick={() => setShowInstagramShareContent(true)}
                >
                  <img
                    className="min-w-[32px]"
                    src="https://launchgood.s3.amazonaws.com/static/rebuild_assets/instagram.svg"
                    alt=""
                  />
                </Button>
              )}
            </SocialButtons>
          </div>
          <ShareLink shareLink={shareUrl} />
          <div className="px-10">
            <p className="text-center text-sm text-rebuild-gray-500 mt-8">
              “Whoever guides someone to goodness will have a reward like the
              one who did it.”
            </p>
            <p className="text-center text-sm text-rebuild-gray-500">
              — Prophet Muhammad ﷺ
            </p>
          </div>
        </div>
      )}

      {enableInstagramShare && showInstagramShareContent && (
        <>
          <button
            className="appearance-none flex gap-2 items-center p-8"
            onClick={() => setShowInstagramShareContent(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
            Back
          </button>
          <InstagramShare slug={slug} shareUrl={shareUrl} />
        </>
      )}

      {!showInstagramShareContent && (
        <div className="flex justify-center">
          <Image {...imgProps} alt={imgProps.alt || ''} />
        </div>
      )}
    </Drawer>
  );
};
