import { imageToBlob } from '@/src/lib';
import { CampaignService } from '@/src/lib/services/v3';
import { InstagramShareImages } from '@/src/types';
import { useEffect, useState } from 'react';
import { ShareLink } from '../../ShareLink';
import { Button, buttonVariants } from '../../ui/Button';

export default function InstagramShare({
  slug,
  shareUrl,
}: {
  slug?: string;
  shareUrl: string;
}) {
  const [currentTab, setCurrentTab] = useState<'story' | 'post'>('story');

  const [instagramShareImages, setInstagramShareImages] =
    useState<InstagramShareImages | null>(null);
  const [shareImageBlobs, setShareImageBlobs] = useState<{
    story: Blob;
    post: Blob;
  } | null>(null);

  useEffect(() => {
    getInstagramShareImages().then((images) => {
      if (!images) return;
      setInstagramShareImages(images);
    });
  }, []);

  useEffect(() => {
    if (!instagramShareImages) return;
    if (shareImageBlobs) return;

    generateShareImageBlobs();
  }, [instagramShareImages]);

  const getInstagramShareImages = async (): Promise<
    InstagramShareImages | undefined
  > => {
    if (instagramShareImages) return instagramShareImages;
    if (!slug) {
      console.error('[InstagramShare] No slug provided');
      return;
    }

    const res = await CampaignService.getInstagramShareImages(slug);
    const { success, message, result } = res;
    if (!success) {
      throw new Error(`Unable to get Instagramm share images: ${message}`);
    }
    return result;
  };

  const generateShareImageBlobs = async () => {
    if (!instagramShareImages) return;

    const storyBlob = await imageToBlob(instagramShareImages.story);
    const postBlob = await imageToBlob(instagramShareImages.post);

    setShareImageBlobs({ story: storyBlob, post: postBlob });
  };

  const handleShare = async () => {
    if (!instagramShareImages || !shareImageBlobs) return;

    const imageToShare = shareImageBlobs[currentTab];
    const file = new File([imageToShare], 'image.jpg', {
      type: imageToShare.type,
      lastModified: Date.now(),
    });

    if (!navigator.canShare || !navigator.share) {
      throw new Error('Web Share API not supported');
    }
    await navigator.share({
      files: [file],
    });
  };

  return (
    <section className="max-h-[75vh] overflow-auto p-8 pt-0">
      <div>
        <h1 className="text-2xl font-bold mb-1">Step 1</h1>
        <p className="text-gray-500">
          Choose whether you want to share this campaign as a story or as a
          post.
        </p>

        {!instagramShareImages && (
          <p className="text-gray-500 mt-4 text-center">Loading images...</p>
        )}

        {instagramShareImages && (
          <div className="mt-4">
            <nav className="w-full flex gap-4 mb-4 rounded-xl p-1 border border-neutral-100">
              <li
                className={`flex justify-center grow py-1 list-none rounded-lg hover:bg-gray-500 ${
                  currentTab === 'story' && 'bg-neutral-100'
                }`}
                onClick={() => setCurrentTab('story')}
              >
                Story
              </li>
              <li
                className={`flex justify-center grow py-1 list-none rounded-lg hover:bg-gray-500 ${
                  currentTab === 'post' && 'bg-neutral-100'
                }`}
                onClick={() => setCurrentTab('post')}
              >
                Post
              </li>
            </nav>
            {currentTab === 'story' ? (
              <img src={instagramShareImages.story} alt="Instagram story" />
            ) : (
              <img src={instagramShareImages.post} alt="Instagram post" />
            )}
          </div>
        )}
      </div>

      <div className="mt-8">
        <h1 className="text-2xl font-bold mb-1">Step 2</h1>
        <p className="text-gray-500 mb-2">
          Copy your unique share link. You&apos;ll need to paste this into your
          post or bio so that your followers can find the campaign.
        </p>

        <ShareLink shareLink={shareUrl} />
      </div>

      <div className="mt-8">
        <h1 className="text-2xl font-bold mb-1">Step 3</h1>
        <p className="text-gray-500 mb-2">Share to Instagram!</p>

        <Button
          className={`${buttonVariants({
            variant: 'primary',
            size: 'xl',
          })} cursor-pointer w-full disabled:cursor-not-allowed`}
          disabled={!shareImageBlobs}
          onClick={handleShare}
        >
          <svg viewBox="0 0 24 24" height="18" width="18" className="mr-2">
            <path
              d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
              fill="#FFF"
            ></path>
          </svg>
          {!shareImageBlobs ? 'Preparing your image...' : 'Share on Instagram'}
        </Button>
      </div>
    </section>
  );
}
