import { useState } from 'react';

export const ShareLink = ({ shareLink }: { shareLink: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Failed to copy text to clipboard: ', error);
      });
  };

  return (
    <div className="text-center text-rebuild-gray-500 rounded-lg bg-rebuild-gray-100 flex items-center justify-between p-2 pl-4">
      <p className="truncate me-4" title={shareLink}>
        {isCopied ? 'Copied!' : shareLink}
      </p>
      <button
        className="appearance-none flex-shrink-0 rounded-lg p-2 transition-all duration-300 hover:bg-neutral-200 active:bg-neutral-300"
        onClick={copyToClipboard}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 8.25V6C16.5 4.75736 15.4926 3.75 14.25 3.75H6C4.75736 3.75 3.75 4.75736 3.75 6V14.25C3.75 15.4926 4.75736 16.5 6 16.5H8.25M16.5 8.25H18C19.2426 8.25 20.25 9.25736 20.25 10.5V18C20.25 19.2426 19.2426 20.25 18 20.25H10.5C9.25736 20.25 8.25 19.2426 8.25 18V16.5M16.5 8.25H10.5C9.25736 8.25 8.25 9.25736 8.25 10.5V16.5"
            stroke="#0F172A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};
