import React from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';

interface SocialButtonsProps {
  internalShareUrl: string;
  children?: React.ReactNode;
}

export const SocialButtons = ({
  internalShareUrl,
  children,
}: SocialButtonsProps) => {
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${internalShareUrl}`;
  const whatsappShareUrl = `whatsapp://send?text=${internalShareUrl}`;
  const xShareUrl = `https://twitter.com/intent/tweet?url=${internalShareUrl}`;

  const openSharePopup = async (shareUrl: string) => {
    await navigator.clipboard.writeText(internalShareUrl);
    window.open(shareUrl, '_blank');
  };

  return (
    <div className="flex gap-4">
      {children}
      <Button
        className={`${buttonVariants({
          variant: 'social',
        })} aspect-square h-full w-full`}
        size="lg"
        onClick={() => openSharePopup(whatsappShareUrl)}
        data-tracking="whatsapp-share-button"
      >
        <img
          className="min-w-[32px]"
          src="https://launchgood.s3.amazonaws.com/static/rebuild_assets/WhatsApp.svg"
          alt=""
        />
      </Button>
      <Button
        className={`${buttonVariants({
          variant: 'social',
        })} aspect-square h-full w-full`}
        size="lg"
        onClick={() => openSharePopup(facebookShareUrl)}
        data-tracking="facebook-share-button"
      >
        <img
          className="min-w-[32px]"
          src="https://launchgood.s3.amazonaws.com/static/rebuild_assets/facebook.svg"
          alt=""
        />
      </Button>
      <Button
        className={`${buttonVariants({
          variant: 'social',
        })} aspect-square h-full w-full`}
        size="lg"
        onClick={() => openSharePopup(xShareUrl)}
        data-tracking="twitter-share-button"
      >
        <img
          className="min-w-[32px]"
          src="https://launchgood.s3.amazonaws.com/static/rebuild_assets/x.svg"
          alt=""
        />
      </Button>
    </div>
  );
};
